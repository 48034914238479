<template>
  <div class="xieyi_warp">
    <div class="title">
      <p>广东善利智能科技有限公司</p>
      <p>天天进步智能备考系统用户协议</p>
    </div>
    <div class="content">
      <p>一、总则 </p>
      1、《广东善利智能科技有限公司天天进步智能备考系统用户协议》（以下简称“本协议”）适用于所有使用天天进步智能备考系统的注册用户【包含天天进步高考智能备考系统学生版（PC端）、天天进步中考智能备考系统学生版（PC端）、天天进步高考智能备考系统学生版（移动端）、天天进步中考智能备考系统学生版（移动端）、天天进步高考智能备考系统教师版（PC端）、天天进步中考智能备考系统教师版（PC端）的用户，以下简称“您”】。本协议一经同意并接受，即形成您与广东善利智能科技有限公司（以下简称“善利名师”）间有法律约束力之文件。您确认，您已详细阅读了本协议所有内容，您充分理解并同意接受本协议的全部内容。
      <div>
        2、如本协议内容发生修改或更新，我们将会在网站提示修改内容并公布。您在使用善利名师提供的各项服务之前，应仔细阅读本协议，如您不同意协议内容，应立即停用天天进步中考智能备考系统系统及相关服务。
      </div>

      <p>二、服务说明 </p>
      <div>
        1、本系统服务内容由善利名师提供，包括但不限于线上中考模拟训练、训练情况分析、训练数据统计、训练题目推送、线上视频课程等。
      </div>
      <div>
        2、本系统（包括PC网页端和安卓平板端）所有服务均附期限，到期终止。用户应在截止日期前享受其购买的服务。
      </div>
      <div>
        3、您仅可以合法使用本系统相关服务，除非得到善利名师事先书面授权，否则，您不得以任何形式改编、复制、传播、垂直搜索、镜像或交易系统中的信息。善利名师许可您个人的、不可转让的、非独占地和非商业的合法使用本系统的权利。本协议未明示授权的其他一切权利仍由善利名师保留，您在行使该些权利时须另行获得善利名师的书面许可，同时善利名师如未行使前述任何权利，并不构成对该权利的放弃。
      </div>

      <p>三、用户帐号</p>
      <div>1、在系统中完成注册程序并付款的用户即成为正式用户，可以获得本系统规定用户所应享有的一切权限。</div>
      <div>2、用户只能按照注册要求使用真实姓名注册。</div>
      <div>3、使用期限：从购买之日起至距离购买之日最近的中考结束之日（有特殊约定的除外）。</div>
      <div>4、用户有义务保证密码和帐号的安全，如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管。因用户的保管疏忽导致帐号非法使用, 本系统不承担任何责任。如有必要，可联系联络本系统客服（电话：4008291883 / 18902267233；微信：shanlimingshi1 / shanlimingshi3），以便本系统采取相应措施。</div>

      <p>四、用户行为规范</p>
      <div>1、除非法律允许或者经善利名师事先书面许可，您使用天天进步中考智能备考系统时不得具有下列行为：</div>
      <div>（1）使用未经善利名师授权或许可的任何软件、插件、外挂、系统或第三方工具对天天进步中考智能备考系统的正常运行进行干扰、破坏、修改或施加其他影响。</div>
      <div>（2）使用天天进步中考智能备考系统提交、制作、上传、复制、发布、传播或转载以下内容：反对宪法所确定的基本原则的；危害国家安全；泄露国家秘密，颠覆国家政权，煽动分裂国家，破坏国家统一，宣扬恐怖主义、极端主义的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侵害未成年人合法权益或者损害未成年人身心健康的；包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；对他人进行暴力恐吓、威胁，实施人肉搜索的，侮辱或者诽谤他人，侵害他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益的；含有任何性或性暗示的；含有辱骂、恐吓、威胁内容的；含有骚扰、垃圾广告、恶意信息、诱骗信息的；散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；使用本系统常用语言文字以外的其他语言文字评论的；与所评论的信息毫无关系的；所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息；含有法律、行政法规禁止的其他内容的信息。</div>
      <div>（3）企图探查、扫描、测试天天进步中考智能备考系统的弱点或实施其他破坏网络安全的行为。</div>
      <div>（4）企图干涉、破坏天天进步中考智能备考系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为。</div>
      <div>（5）对天天进步中考智能备考系统进行反向工程、反向汇编、编译或者以其他方式尝试发现本系统的源代码。</div>
      <div>（6）恶意注册、使用天天进步中考智能备考系统账号，包括但不限于频繁、批量注册账号、建立虚假账号、故意冒充他人等。</div>
      <div>（7）提交、发布虚假信息，或盗用他人账号冒充任何人或机构，或以任何方式方式谎称或误导，使人误认为与任何人或任何机构有关。</div>
      <div>（8）利用天天进步中考智能备考系统的账户服务从事任何违法犯罪活动的。</div>
      <div>（9）违反法律法规、本协议、天天进步中考智能备考系统的相关规则及侵犯他人合法权益或天天进步中考智能备考系统未明确授权的其他行为。</div>
      <div>2、未经善利名师书面许可，任何人或机构，不得自行或授权、允许、协助其他人或机构复制、读取、抓取或采用天天进步中考智能备考系统中的任何信息内容（亦不可擅自编辑修改后使用），用于包括但不限于宣传、增加阅读量、浏览量等商业用途。</div>
      <div>3、善利名师有权对用户使用天天进步中考智能备考系统的情况进行审查和监督，如善利名师有理由认为用户在使用天天进步中考智能备考系统时违反上述任何规定，善利名师或其授权的人或机构在任何时候有权进行独立判断并采取技术手段予以删除、屏蔽、断开链接，无需任何通知，同时，善利名师有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、或终止天天进步中考智能备考系统账号使用，追究相关法律责任等措施。</div>

      <p> 五、隐私政策</p>
      <div>1、征集用户隐私权限</div>
      <div>（1） 在您注册本系统帐号时，您根据本系统要求提供的个人注册信息（包含姓名、电话、所在学校、年级、班别等），用于对您进行学情分析和训练数据统计；</div>
      <div>（2）本系统收集到的您在本系统提交的有关信息数据，包括但不限于训练次数、训练得分、答题图片，用于对您进行学情分析和训练数据统计；</div>
      <div>（3）需要调用摄像头权限，用于拍照上传您的答题卡。</div>
      <div>2、信息安全</div>
      <div>（1）本系统不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可。</div>
      <div>（2）本系统亦不允许任何第三方以任何手段收集、编辑、出售或者传播您的个人信息。任何本系统用户如从事上述活动，一经发现，本系统有权立即终止与该用户的服务协议。</div>
      <div>（3）本系统帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本系统将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。</div>
      <div>（4）本系统通过了国家网络安全等级保护（二级）的备案和测评。</div>
      <div>3、信息披露</div>
      <div>除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：</div>
      <div>（1）事先获得用户的明确授权；</div>
      <div>（2）根据有关的法律法规要求；</div>
      <div>（3）按照相关司法机关和/或政府主管部门的要求；</div>
      <div>（4）为维护社会公众的利益所必需且适当；</div>
      <div>（5）为维护我公司或用户的合法权益所必需且适当。</div>
      <div>4、信息存储</div>
      <div>本系统收集的有关您的信息和资料将保存在本系统及服务器上。</div>
      <div>5、账号注销</div>
      <div>用户如需要查询、更正个人信息或注销账号，可通过注册账号时预留的联系电话拨打4008291883 / 18902267233，或添加客服人员微信号shanlimingshi1 / shanlimingshi3联系客服人员查询、修改个人信息或注销账号。</div>
      <div>6、本隐私政策的更改</div>
      <div>如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。</div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.xieyi_warp {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 86rem;
  .title {
    text-align: center;
    font-size: 22rem;
    font-weight: 800;
    color: #000000;
    p {
      margin-bottom: 0;
    }
  }
  .content {
    margin: 20px;
    font-size: 20rem;
    color: #333333;
    p {
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
}
</style>